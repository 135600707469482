<template>
    <div class="container_home" ref="container_home">
        <Header />
        <div class="carousel" @mouseenter="mouseenter_carousel" @mouseleave="initCarousel">
            <ul ref="carouselRef">
                <li v-for="(item, index) in carousel.imgs" :key="index">
                    <img :src="item" alt draggable="false" />
                </li>
            </ul>
            <div class="radioBox">
                <input type="radio" :class="index === carousel.currentIdx ? 'checked' : ''"
                    v-for="(item, index) in carousel.imgs" :key="index" :checked="index === carousel.currentIdx"
                    @click="setCarouselCurrentIdx(index)" />
            </div>
            <div class="inBox1 fl cardContent">
                <div class="inTit1">
                    <div class="titText fl">
                        <span class="inTitIco1"></span>
                        <span :class="tabs.activeName === item.name ? 'active' : ''" v-for="item in tabs.list"
                            :key="item.name" @click="tabs.activeName = item.name">
                            <span>{{ item.label }}</span>
                            <small>|</small>
                        </span>
                    </div>
                </div>
                <div>
                    <ul class="inList3">
                        <li v-for="(item, index) in tabs.data" :key="index">
                            <a :href="`/layout/noticeDetail?newsId=${item?.id}`">
                                <span>{{ item?.createTime | formatTime }}</span>
                                {{ item?.title }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="eightBigUser">
            <li v-for="(item, idx) in eightBigUser" :key="idx">
                <main class="imgBox">
                    <img :src="require(`@/assets/eightBigUser/${item.title}.png`)" alt />
                </main>
                <h3>{{ item.title }}</h3>
                <p>{{ item.desc }}</p>
            </li>
        </div>
        <div class="selectMe">
            <h1 class="beautifyFontStyle">选择我们的理由及平台优势</h1>
            <div class="row">
                <div class="col">
                    <img src="@/assets/img/platform.png" alt="">
                    <h3>完善便捷的平台功能</h3>
                    <p>除了电脑端的功能以外，本平台还为学员提供了手机端的功能。学员拿着手机就可以随时随地进行学习和练习，提升了学习的便捷程度和学习效果。</p>
                </div>
                <div class="col">
                    <img src="@/assets/img/throughRate.png" alt="">
                    <h3>行业领先的通过率</h3>
                    <p>依据历次试的大数据对比分析发现。经过我们的专业培训以及辅导，我们学员的通过测试率显著高于其他非官方培训机构。</p>
                </div>
                <div class="col">
                    <img src="@/assets/img/technology.png" alt="">
                    <h3>专业资深的技术团队</h3>
                    <p>平台由专业资深的技术团队提供技术服务，能够同时满足数以百万计的学员同时在线学习。平台会定期进行选代升级。</p>
                </div>
            </div>
        </div>
        <div class="helper">
            <header>使用帮助及常见问题解答</header>
            <div class="box" ref="helperBox">
                <div class="col">
                    <div class="inner">
                        <h3>关于平台</h3>
                        <p>
                            河北建筑培训在线学习平台是由北京京人教育科技有限公司开发。
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="inner">
                        <h3>关于浏览器</h3>
                        <p>
                            平台基于 Chromium 内核的浏览器开发，推荐使用没有广告、干净、纯净的 Microsoft Edge 浏览器、Google Chrome 浏览器或者 QQ 浏览器、搜狗浏览器、360
                            极速浏览器。
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="inner">
                        <h3>关于学员使用</h3>
                        <p class="sessionStyle">
                            平台包含了专为学员使用的在线学习系统，报名的课程审核通过后学员可使用身份证号进行登录。登录成功后会自动跳转至在线学习系统。
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="inner">
                        <h3>关于企业使用</h3>
                        <p>
                            平台包含了专为企业使用的管理系统，企业完成注册后可使用注册账号进行登录。登录成功后会自动跳转至管理系统。
                        </p>
                    </div>
                </div>
            </div>

        </div>
        <div class="links">
            <p>
                <span class="inTitIco1"></span>友情链接
            </p>
            <a v-for="(item, idx) in friendLinksList" :key="idx" target="_blank" :href="item.linkHref">
                <span>{{ item.linkName }}</span>
            </a>
        </div>
        <div class="mobileCode">
            <img src="@/assets/img/mobileCode.png" />
            <p>手机扫描登录</p>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { BASEURL } from "@/api/model/request";
export default {
    components: { Header, Footer },
    data() {
        return {
            baseUrl: BASEURL,
            carousel: {
                //轮播图
                currentIdx: 0,
                isLast: false,
                direction: "right",
                imgs: [
                    require("/src/assets/carousel/1.png"),
                    require("/src/assets/carousel/2.png"),
                ]
            },
            tabs: {
                activeName: "1",
                list: [
                    { name: "1", label: "通知公告" },
                    { name: "2", label: "政策法规" }
                ],
                data: [],
                dataMap: {
                    '1': 'categoryList_cardLeft',
                    '2': 'categoryList_cardRight',
                },
            },
            eightBigUser: [
                { title: "施工员", desc: "在建筑与市政工程施工现场，从事施工组织策划、施工技术与管理，以及施工进度、成本、质量和安全控制等工作的专业人员。" },
                { title: "质量员", desc: "在建筑与市政工程施工现场，从事施工质量策划、过程控制、检查、监督、验收等工作的专业人员。" },
                { title: "安全员", desc: "在建筑与市政工程施工现场，从事施工安全策划、检查、监督等工作的专业人员。" },
                { title: "标准员", desc: "在建筑与市政工程施工现场，从事工程建设标准实施组织、监督、效果评价等工作的专业人员。" },
                { title: "材料员", desc: "在建筑与市政工程施工现场，从事施工材料的计划、采购、检查、统计、核算等工作的专业人员。" },
                { title: "机械员", desc: "在建筑与市政工程施工现场，从事施工机械的计划、安全使用监督检查、成本统计及核算等工作的专业人员。" },
                { title: "劳务员", desc: "在建筑与市政工程施工现场，从事劳务管理计划、劳务人员资格审查与培训，劳动合同与工资管理、劳务纠纷处理等工作的专业人员。" },
                { title: "资料员", desc: "在建筑与市政工程施工现场，从事施工信息资料的收集、整理、保管、归档、移交等工作的专业人员。" },
            ],
            usAdvantage: [
                { title: "响应式布局", icon: "buju" },
                { title: "在线商城应用安装", icon: "icon_yingyongguanli" },
                { title: "基于B/S网站建设", icon: "jianshe-" },
                { title: "视觉设计趋势", icon: "shijuezhineng" }
            ],
            courseList: [], //推荐课程
            teacherList: [], //师资列表
            categoryList_cardLeft: [], //通知公告
            categoryList_cardRight: [], //政策法规
            friendLinksList: [], //友情链接
            titleList: [] //系统导航
        };
    },
    filters: {
        formatTime(dateTime) {
            //yyyy-MM-dd HH:mm:ss => yyyy-MM-dd
            return dateTime.substr(0, 10);
        },
    },
    watch: {
        "tabs.activeName"(field) {
            const dataSource = this.tabs.dataMap[field];
            this.tabs.data = this[dataSource];
        },
        "carousel.currentIdx"(idx) {
            // console.log('watch carousel.currentIdx change', idx);
            const dom = this.$refs.carouselRef;
            const len = 643 * idx;
            dom && (dom.scrollLeft = len);
        }
    },
    created() {
        this.initDataByApi();
        this.initCarousel();
    },
    mounted() {
        this.addMouseEventByCarousel();
        this.setHelperMouseEvent();
        this.addEventScrollChange();

    },
    methods: {
        addEventScrollChange() {
            const dom = this.$refs.container_home;
            this.$bus.$on('scrollChange', ({ scrollTop }) => {
                dom.scrollTop = scrollTop;
            })
        },
        setHelperMouseEvent() {
            //跟随鼠标的边框灰色背景
            const parentDom = this.$refs.helperBox;
            const childDoms = parentDom.querySelectorAll('.col');
            const rectDetail = childDoms.length != 0 && childDoms[0].getBoundingClientRect();
            const rectWidth = rectDetail.width;
            const rectHeight = rectDetail.height;
            const getClientPosition = dom => {
                const { top, left } = dom.getBoundingClientRect()
                return [left, top];
            }
            parentDom.onmouseenter = () => {
                parentDom.onmousemove = (e) => {
                    childDoms.forEach((dom) => {
                        const arr = getClientPosition(dom);//children相对视口位置
                        const x = e.x - arr[0] - rectWidth / 2;
                        const y = e.y - arr[1] - rectHeight / 2;
                        dom.style.setProperty('--position_x', x);
                        dom.style.setProperty('--position_y', y);
                    })
                }
                parentDom.onmouseleave = () => { }
            }
        },
        setCarouselCurrentIdx(idx) {
            // console.log('click setCarouselCurrentIdx', idx);
            this.carousel.currentIdx = idx;
            this.$forceUpdate();
        },
        addMouseEventByCarousel() {
            const dom = this.$refs.carouselRef;
            const sliceWidth = window.innerWidth / 2;
            const func_down = e => {
                dom.style.scrollBehavior = "auto";
                document.documentElement.addEventListener("mousemove", func_move);
            };

            const func_move = e => {
                dom.scrollLeft -= e.movementX;
            };

            const func_up = e => {
                dom.style.scrollBehavior = "smooth";
                const originIdx = this.carousel.currentIdx;
                const imgLen = this.carousel.imgs?.length || 0;
                let targetIdx = e.pageX < sliceWidth ? originIdx + 1 : originIdx - 1;
                targetIdx = targetIdx % imgLen;
                targetIdx < 0 && (targetIdx = 0);
                this.carousel.currentIdx = targetIdx;
                document.documentElement.removeEventListener("mousemove", func_move);
            };
            dom.addEventListener("mousedown", func_down);
            document.documentElement.addEventListener("mouseup", func_up);
        },
        mouseenter_carousel() {
            clearInterval(this.timer_carousel);
            this.timer_carousel = null;
        },
        jumpPage_noticeDetail(item) {
            const { id } = item;
            const path = "/layout/noticeDetail?newsId=" + id;
            this.$router.push(path);
        },
        initCarousel() {
            //transform:`translateX(-${1190*carousel.currentIdx}px)`,
            //012101210
            //012012012
            //021021
            let originIdx = this.carousel.currentIdx;
            const len = this.carousel.imgs.length;
            this.timer_carousel = setInterval(() => {
                if (this.carousel.direction === "right") {
                    originIdx++;
                } else {
                    originIdx--;
                }
                this.carousel.isLast = originIdx === len;
                originIdx = originIdx % len;
                if (originIdx === 0) {
                    this.carousel.direction = "right";
                }
                if (originIdx === len - 1) {
                    this.carousel.direction = "left";
                }
                // console.log("isLeaf", this.carousel.isLast);
                this.carousel.currentIdx = originIdx;
            }, 3000);
        },
        async initDataByApi() {
            const reqMap = [
                {
                    serverFunc: "getCourseList",
                    dataField: "courseList"
                },
                {
                    serverFunc: "getStudentList",
                    dataField: "teacherList"
                },
                {
                    serverFunc: "getCategoryList",
                    dataField: "categoryList_cardLeft",
                    params: {
                        cid: "1", //文章分类ID
                        s: 5 //最近的多少条
                    }
                },
                {
                    serverFunc: "getCategoryList",
                    dataField: "categoryList_cardRight",
                    params: {
                        cid: "2", //政策法规
                        s: 5 //最近的多少条
                    }
                },
                {
                    serverFunc: "getFriendLinksList",
                    dataField: "friendLinksList"
                },
                {
                    serverFunc: "getSysNavList",
                    dataField: "titleList",
                    resMode: "res.data"
                }
            ];
            const api = this.$api;
            reqMap.forEach(async item => {
                const { serverFunc, dataField, resMode, params } = item;
                const res = await api[serverFunc](params);
                this[dataField] = resMode ? res.data : res;
                //初始化tabs
                if (serverFunc === "getCategoryList") {
                    this.tabs.data = res || [];
                }
                //反转
                if (serverFunc == "getFriendLinksList") {
                    this[dataField] = res && (typeof res.reverse === 'function') && res.reverse();
                }

            });
        },
        async getCategoryListByApi(cid) {
            // const res = await this.$api.getCategoryList({ cid, s: 5 });
            // this.categoryList = res;
        },
        scrollTo(mode, type) {
            //mode：left、right
            //type：help、course、teacher
            const ref = this.$refs[type];
            const scrollLeft = ref.scrollLeft;
            const distance = 200;
            ref.scrollLeft =
                mode == "left" ? scrollLeft - distance : scrollLeft + distance;
        }
    }
};
</script>

<style lang="scss" scoped >
@import "@/style/mixin_scrollBar.scss";
@import "@/style/home.scss";
@import "@/style/var.scss";
@use 'sass:math';

.container_home {
    @include mixin_scrollBar;
    scroll-behavior: smooth;
}

.help .wheel ul {
    @include mixin_scrollBar;
    scroll-behavior: smooth;
}

.course .wheel ul {
    @include mixin_scrollBar;
    scroll-behavior: smooth;
}

.mobileCode {
    position: fixed;
    right: 10px;
    top: 40%;
    width: 200px;
    height: 200px;

    img {
        width: 100%;
        height: 100%;
        border: 6px solid rgb(0, 47, 167);
        box-sizing: border-box;
    }

    p {
        background-color: rgb(0, 47, 167);
        margin: 0;
        color: white;
        text-align: center;
    }

}
</style>