/* 
提供给SuperForm=>index.vue处理数据变化时，联动获取绑定字段的接口数据的流程
config.custom.binding=
    bindField: "value", //我的哪个字段名作为参数，默认是valueField定义的
    list: [
      //当我改变的时候，哪些字段要受我的影响发生改变
      {
        field: "field7", //受影响的具体字段名，按格式在此一一列举
        event: "dynamicParams_field16", //触发这个字段对应的事件监听，从而重新获取接口数据
      },
    ],
}
*/

export default {
    computed: {
        getNeedBindingCols() {
            const allCols = this.getAllColByFlat;
            return allCols.filter(it => it.binding instanceof Object);
        }
    },
    mounted() {
        this.mixin_render_binding();
    },
    methods: {
        mixin_render_binding() {
            const cols = this.getNeedBindingCols;
            cols.forEach(col => {
                const { binding, model } = col;
                this.mixin_renderBindingByCol(model, binding);
            });
        },
        mixin_renderBindingByCol(model, binding = {}) {
            const { formatParams, emitEventlist } = binding;
            //记录思路：如果遇到不同字段需要配置不同的参数这种情况，那么，将watch的第二个函数提取出去，在col中重写这个方法，返回数组或自定义实现
            this.$watch(() => this.private_form[model], (val) => {
                // console.log("watch value change", val);
                const params = formatParams(val, this.private_form);
                emitEventlist.forEach(eventName => {
                    this.$bus.$emit(eventName, params);
                })
            })
        }
    }
}