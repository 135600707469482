
function Get(path) {

    return (parent, fnName, descriptor) => {

        console.log('拿到请求', parent, path, descriptor);
        descriptor.value = () => { }
    }
}
function Handle2(path) {

}

class Test {

    @Get("http://localhost:")
    static insetFn(data) {
        console.log('inset run======>', data);

    }

}

export default Test;
// Test.insetFn('test');

