<template>
    <div id="app" class="mixin_scrollBar">
        <transition enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut" duration="100">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
export default {
    name: "App",
    components: {}
};
</script>

<style lang="scss">
//颜色备份：#4389da
@import url("@/style/global.scss");
@import url("@/style/mixin_scrollBar.scss");
</style>
