/**
 * @Author: HJK
 * @Date: 2023-05-18 15:25:27
 * @LastEditTime: 2023-08-28 14:26:44
 * @LastEditors: HJK
 * @Description: 
 * @FilePath: \badayuan_student\student\src\router\index.js
 */
// 导入组件
import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import Store from '@/store';
Vue.use(Router);


const router = new Router({
    mode: 'history',
    routes
})



router.beforeEach((to, from, next) => {
    if (to.matched.length != 0) {
        const isLogin = Store.getters.getLoginStatus || false;
        if (to.meta.requireAuth) {
            if (isLogin) {
                next();
            } else {
                next('/')
            }
        } else {
            next();
        }
    } else {
        next('/');
    }
})


const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;

//帮我解决 vue-router 报错：Navigation cancelled from “/...“ to “/...“ with a new navigation
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

Router.prototype.replace = function push(location) {
    return originalReplace.call(this, location).catch(err => err);
};



export default router;