/**
 * 首页
 */
import APIDOC from '../model/serverApi';
import { get, post } from '../model/request';

const { course, teacher, category, friendLinks, sysNavList,config } = APIDOC.HOME;

export function getCourseList() { return get(course) }//推荐课程
export function getStudentList() { return get(teacher) }//师资列表
export function getCategoryList(params) { return get(category,params) }//通知公告
export function getFriendLinksList() { return get(friendLinks) }//友情链接
export function getSysNavList(params = {}) { return post(sysNavList, params) }//系统导航
export function getAllConfig(){return get(config)}//获取全部系统配置
