//罗列所有表单组件的类型

export default [
    'empty',//【00】普通文本，只展示
    'text', //【01】输入框
    'number', //【02】数字框
    'textarea', //【03】文本域，注意，不能变成小驼峰
    'select', //【04】下拉框
    'select_multiple', //【05】多选下拉框
    'cascader', //【06】级联框，暂时还没有这个功能
    'radio', //【07】单选框
    'switch', //【08】switch开关
    'datetime', //【09】日期时间格式：YYYY-MM-DD HH:mm:ss
    'date', //【10】日期格式：YYYY-MM-DD
    'month', //【11】月格式：YYYY-MM
    'year', //【12】年格式：YYYY
    'dateTimeRange', //【13】日期时间范围格式：[YYYY-MM-DD HH:mm:ss,YYYY-MM-DD HH:mm:ss]
    'cardUpload', //【14】正方体上传图片
    'btnUpload', //【15】按钮上传文件
    'checkBox', //【16】checkBox多选框
]
