<template>
  <el-dialog
    :title="options.title || '提示'"
    :visible="options.visible"
    :width="options.width || '30%'"
    :append-to-body="true"
    custom-class="myDialog"
    :before-close="
      () => {
        options.visible = false;
        $emit('cancel');
      }
    "
  >
    <slot />
    <span slot="footer" class="dialog-footer" v-if="options.footer != false">
      <el-button @click="handleCancel" class="danger" type="danger" size="mini">取 消</el-button>
      <el-button
        type="primary"
        @click="$emit('ok')"
        class="primary"
        size="mini"
        v-loading="options.loading"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
//弹出层的公共组件
/*
     Dialog: {
        title: "头部展示文本",
        visible: false,
        width: 800,
      },
*/
export default {
  name: "Dialog",
  props: ["options"],
  created() {},
  methods: {
    handleCancel() {
      this.options.visible = false;
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss">
@import url("~@/style/mixin_scrollBar.scss");
// .el-dialog__wrapper {
//   .scrollBar();
// }
.el-dialog {
  margin-top: 45px !important;
  .el-dialog__header {
    border-bottom: 1px solid #ccc;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>