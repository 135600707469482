/**
 * @Author: HJK
 * @Date: 2023-06-05 15:45:07
 * @LastEditTime: 2023-09-20 11:32:35
 * @LastEditors: HJK
 * @Description: 
 * @FilePath: \badayuan_student\student\src\filters\index.js
 */
// import * as requestModel from "@/api/model/request.js";
import Vue from "vue";
// const BASEURL = requestModel.BASEURL;
// const BASEURL = "https://badayuan.bj.bcebos.com/";
const BASEURL = "/";




const filters = [
    {
        name: "getPathByAddBaseUrl",//所有图片的地址加前缀规则
        func: (path) => {
            return BASEURL + path;
        }
    }, {
        name: "$formatTime2MS",//将毫秒转成时分钟秒的形式
        func(ms) {
            if (ms === undefined) return 0;
            const totalSeconds = Math.ceil(ms / 1000);
            const hours = parseInt(totalSeconds / 3600);
            const minutes = parseInt((totalSeconds % 3600) / 60);
            const seconds = parseInt(totalSeconds % 60);
            return seconds == 0 ? 0 : (`${hours}小时 : ${minutes}分钟 : ${seconds}秒`);
        },
    }
]
filters.forEach(item => {
    Vue.filter(item.name, item.func);
})