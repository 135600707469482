<template>
  <div class="container_layout">
    <Header type="layout" />
    <main :class="{showAside:!getAsideStausByRouteMeta}">
      <Aside />
      <transition name="app-main" mode="out-in">
        <router-view></router-view>
      </transition>
    </main>
    <Footer type="layout" />
  </div>
</template>

<script>
//登录后的页面
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Aside from "@/components/Aside.vue";

export default {
  components: { Header, Footer, Aside },
  computed: {
    getAsideStausByRouteMeta() {
      // console.log("route.meta.asideHide", this.$route.meta);
      return !!this.$route.meta.siderHide;
    }
    // getAsideStatus() {
    //   return this.$store.getters.getAsideShowStatus;
    // }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/mixin_scrollBar.scss";

.container_layout {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  @include mixin_scrollBar;

  > * {
    width: 100%;
  }

  > header {
    // height: 80px;
    // color: #3661af;
    font-family: "Courier New", Courier, monospace;
  }

  > main {
    background-color: #f3f3f3;
    box-shadow: 0 2px 10px lightgrey inset;
    display: flex;
    box-sizing: border-box;
    min-height: 900px;
    position: relative;
    // padding: 0 10% 0 10% ;
    justify-content: center;
    // padding: 0 5%;
    padding-right: 10%;
    margin-top: 1em;
    &.showAside {
      padding: 0 13%;
      > aside {
        transform: scaleX(1) translateX(0px);
        opacity: 1;
        flex: 0 0 250px;
      }
      > main {
        justify-self: center;
      }
    }
    > aside {
      flex: 1 1 auto;
      transform: scaleX(0) translateX(-100px);
      opacity: 0;
      transition: 0.2s ease;
      transform-origin: left;
    }
    > main {
      flex: 1 1 calc(100% - 250px);
      background-color: #f3f3f3;
      width: calc(100% - 250px);
      padding: 5px;
    }
  }

  > footer {
    // height: 60px;
  }
}
</style>

<style>
/* // page in-out animate=> */
.app-main-enter {
  opacity: 0;
  transform: translateX(10px);
}

.app-main-enter-to,
.app-main-leave {
  opacity: 1;
  transform: translateX(0px);
}

.app-main-enter-active,
.app-main-leave-active {
  transition: 0.2s ease;
}

.app-main-leave-to {
  opacity: 0;
  transform: translateX(10px);
}

/* //<=page in-out animate */
</style>