<template>
    <footer :class="{ container_footer: true, isLayout: type == 'layout' }">
        <p class="links">
            <span @click="handleClick('goTop')">回到顶部</span> |
            <span>关于我们</span> |
            <span>联系我们</span>
        </p>
        <p>
            <span>京ICP备16028401号-13</span>
            <img src="@/assets/img/police_badge.png" />
            <span>京公网安备 11010502052311号</span>
        </p>
        <p>技术支持：北京京人教育科技有限公司</p>
    </footer>
</template>

<script>
export default {
    props: ["type"],
    computed: {
        getIsLayout() {
            return this.type == "layout";
        }
    },
    methods: {
        handleClick(type) {
            switch (type) {
                case 'goTop':this.$bus.$emit('scrollChange', 0);break;
                default: return;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";

.container_footer {
    height: auto;
    //   background-color: $homeBaseColor;
    // padding: 0 280px;
    position: relative;
    border-radius: 5px 5px 0 0 !important;

    >.links {
        font-size: 12px;
        line-height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
        border-bottom: 1px solid #4074b1;

        span {
            margin: 0;
            font-size: 20px;
            color: #FFF;
            padding: 0px 5px;
            text-decoration: none;
            cursor: pointer;

            &:hover {
                font-weight: bolder;
            }
        }
    }

    >p {
        margin: 0.5em 0 0.5em 1em;
        // color: white;
        padding-left: 1em;
        text-align: center;
        color: #fff;
        font-size: 20px;



        >span {
            margin: 1em;
        }
    }
}

.container_footer.isLayout {
    background-color: $homeBaseColor;
    overflow-x: hidden;
    width: 100%;
    // padding: 1em 230px;

    >hr {
        width: calc(100% - 20%);
    }
}
</style>