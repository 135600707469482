

export default {
    LOGIN: {
        in: "/student/common/login",
        out: "/student/common/logOut"
    },
    UPLOAD: '/system/upload/file2',
    UPLOAD_base64: '/system/upload/Base64Image',
    HOME: {//首页
        config: "/system/config/all",//全部系统设置接口
        course: "/student/common/recommonCourseList",//推荐课程
        teacher: "/student/common/teachers",//师资列表
        category: "/student/common/newsList",//通知公告
        friendLinks: "/student/common/friendLinks",//友情链接
        sysNavList: "/system/nav/list",//系统导航
    },
    DICT: {//字典
        classYear: "/system/class/clsYear",//所有年份
    },
    LAYOUT: {//登录之后的页面
        setting: {//个人设置
            getDetail: "/student/personal/info/detail",//个人信息
            updateInfo: "/student/personal/info/update",//个人信息修改
            resetPwd: "/student/personal/resetPwd",//密码修改
        },
        myClass: {
            getList: "/student/class/myClass",//学生报名的班级列表
            studyReport: "/student/study/studyReport",//学习报告
            studyArchives: "/student/study/studyArchives",//学习档案
            studyLogList: "/student/study/log/list",//学生端学习记录列表
            studyFaceChapterRecord: "/student/face/chapter/record",//获取某个学生章节的抓拍详情
            faceChapterRecord: "/student/face/chapter/record",//获取某个学生章节的抓拍详情
        },
        myCourse: {
            lastStudyCourseList: "/student/common/lastStudyCourseList",//最近学习课程列表
            mycourse: "/student/course/mycourse",//我的课程分页列表
            courseDetail: "/student/course/courseDetail",//课程详情展示
        },
        notice: {
            commonList: '/student/common/newsList',//通知公告列表
            commonInfo: "/system/news/info",//通知公告详情
        },
        myMsg: {
            list: "/student/common/msgList",//我的消息分页列表
        },
        personal: {
            info: "/student/personal/info/card",//获取实名认证信息
            update: "/student/personal/info/card",//更新实名认证
        },
        play: {
            save: "/student/study/log/save",//学生端学习记录保存
            load: "/student/course/learn",//播放页课程信息加载
            uploadFace: "/student/face/save/capture",//人脸抓拍记录上传
            faceCheck: "/student/face/check",//人脸验证接口
        }

    }
};


