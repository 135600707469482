

//type=>success/warning/info/error	
const codeByType = {
    '100': 'info',
    '200': 'success',
    '400': 'error',
}

import { Message } from "element-ui";
export default (res, customConfig = {}) => {
    const { code, msg } = res;
    const type = customConfig.type || codeByType['' + code] || 'warning';
    Message({
        type,
        message: msg,
        showClose: true,
        ...customConfig
    })

}