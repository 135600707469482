

/**
 * 字典表
 */
import APIDOC from '../model/serverApi';
import { get, post } from '../model/request';
const DICT = APIDOC.DICT;


export const getDictClassYear = () => get(DICT.classYear);//获取所有年份
