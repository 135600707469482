/**
 * @Author: HJK
 * @Date: 2023-05-19 11:42:33
 * @LastEditTime: 2023-08-29 09:17:20
 * @LastEditors: HJK
 * @Description: 
 * @FilePath: \badayuan_student\student\src\utils\index.js
 */
import validateForm from "./validateForm";

function formatTime(s) {
    if (s === undefined) return 0;
    const hours = parseInt(s / 3600);
    const minutes = parseInt((s % 3600) / 60);
    const seconds = parseInt(s % 60);
    return seconds == 0 ? 0 : (`${hours} 时 ${minutes} 分 ${seconds}秒`);
}

export default {
    validateForm,
    formatTime
}