/**
 * @Author: HJK
 * @Date: 2023-05-18 15:15:38
 * @LastEditTime: 2023-09-19 17:34:08
 * @LastEditors: HJK
 * @Description: 
 * @FilePath: \badayuan_student\student\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import utils from "./utils";
import api from "./api";
import msg from "./utils/msg";
import cache from "./utils/cache";
import * as animated from 'animate.css';
import SuperForm from "./components/super_form"
import bus from "@/utils/eventBus";
import directive_drag from "@/directives/drag";
import directive_fullScreen from "@/directives/fullScreen";
import "@/filters/index";
ElementUI.Dialog.props.closeOnClickModal.default = false;



Vue.use(animated)
Vue.use(ElementUI);
Vue.directive('drag', directive_drag);
Vue.directive('fullScreen', directive_fullScreen);
Vue.component('SuperForm', SuperForm);
Vue.prototype.$utils = utils;
Vue.prototype.$api = api;
Vue.prototype.$msg = msg;
Vue.prototype.$bus = bus;
Vue.prototype.$cache = cache;
Vue.config.productionTip = false
store.dispatch("initLoginCache");


import TestClass from "@/api/test/index";
TestClass.insetFn("test")


new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
